//import hook react
import React, { useEffect, useState } from "react";

//import hook useParams react router dom
import { useParams } from "react-router-dom";

//import layout web
import LayoutWeb from "../../../layouts/Web";

//import BASE URL API
import Api from "../../../api";

//import card place component
import CardPlace from "../../../components/utilities/CardPlace";

function WebDestinationsShow() {
  const [category, setCategory] = useState({});
  const [places, setPlaces] = useState([]);

  //get params from url
  const { slug } = useParams();

  //function "fetchDataCategory"
  const fetchDataCategory = async () => {
    //fetching Rest API
    await Api.get(`/api/web/categories/${slug}`).then((response) => {
      //set data to state "category"
      setCategory(response.data.data);

      //set data to state "places"
      setPlaces(response.data.data.places);

      //set title from state "category"
      document.title = `Destinasi : ${response.data.data.name} - Boalemo Tourism`;
    });
  };

  //hook
  useEffect(() => {
    //call function "fetchDataCategory"
    fetchDataCategory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  return (
    <React.Fragment>
      <LayoutWeb>
        <section
          className="inner-banner"
          style={{ backgroundImage: "url(" + category.image + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="banner-content">
                  <h2>{category.name}</h2>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/">Beranda</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Destinasi
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-spacing blogs">
          <div className="container">
            <div className="row">
              {places.length > 0 ? (
                places.map((place) => (
                  <CardPlace
                    key={place.id}
                    id={place.id}
                    slug={place.slug}
                    title={place.title}
                    images={place.images}
                    address={place.address}
                  />
                ))
              ) : (
                <div className="alert alert-danger" role="alert">
                  <strong>Opps...!</strong> Data Belum Tersedia!.
                </div>
              )}
            </div>
          </div>
        </section>

        <svg
          style={{ backgroundColor: "#fcfcfc" }}
          className="d-none d-md-block"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 68"
        >
          <path
            d="m1622.3 1937.7c0 0-410.7 169.1-913.4 75.5-502.7-93.6-977.7 56.3-977.7 56.3v440h1891.1v-571.8"
            fill="#17233E"
            transform="translate(0-1977)"
          ></path>
        </svg>
      </LayoutWeb>
    </React.Fragment>
  );
}

export default WebDestinationsShow;
