//import react
import React, { useState, useEffect } from "react";

//import layout
import LayoutAdmin from "../../../layouts/Admin";

import Sidebar from "../../../components/admin/Sidebar";

//import BASE URL API
import Api from "../../../api";

//import js cookie
import Cookies from "js-cookie";

//import Link from react router dom
//import { Link } from "react-router-dom";

//import pagination component
import PaginationComponent from "../../../components/utilities/Pagination";

//import toats
import toast from "react-hot-toast";

//import react-confirm-alert
import { confirmAlert } from 'react-confirm-alert';

//import CSS react-confirm-alert
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

function GalleryIndex() {

    //title page
    document.title = "Galeri Foto — Boalemo Tourism";

    //state posts
    const [getData, setGallery] = useState([]);

    //state currentPage
    const [currentPage, setCurrentPage] = useState(1);

    //state perPage
    const [perPage, setPerPage] = useState(0);

    //state total
    const [total, setTotal] = useState(0);
    
    //token
    const token = Cookies.get("token");

    //function "fetchData"
    const fetchData = async (pageNumber) => {

        //define variable "page"
        const page = pageNumber ? pageNumber : currentPage;

        //fetching data from Rest API
        await Api.get(`/api/admin/gallery?page=${page}`, {
            headers: {
                //header Bearer + Token
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            //set data response to state "Gallery"
            setGallery(response.data.data.data);

            //set currentPage
            setCurrentPage(response.data.data.current_page);

            //set perPage
            setPerPage(response.data.data.per_page);

            //total
            setTotal(response.data.data.total);
        });
    };

    //hook
    useEffect(() => {
        //call function "fetchData"
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //function "deleteGallery"
    const deleteGallery = (id) => {

        //show confirm alert
        confirmAlert({
            title: 'Anda yakin menghapus data ini?',
            message: 'Tindakan ini tidak dapat dikembalikan!!!',
            buttons: [{
                    label: 'YES',
                    onClick: async () => {
                        await Api.delete(`/api/admin/gallery/${id}`, {
                                headers: {
                                    //header Bearer + Token
                                    Authorization: `Bearer ${token}`,
                                }
                            })
                            .then(() => {

                                //show toast
                                toast.success("Gambar Berhasil Dihapus!", {
                                    duration: 4000,
                                    position: "top-right",
                                    style: {
                                        borderRadius: '10px',
                                        background: '#333',
                                        color: '#fff',
                                    },
                                });

                                //call function "fetchData"
                                fetchData();
                            })
                    }
                },
                {
                    label: 'NO',
                    onClick: () => {}
                }
            ]
        });
    }

    // const styles = {
    //     action: {
    //         position: "absolute",
    //         right: "20px",
    //         top: "11px"
    //     }
    // }

    return (
        <React.Fragment>
            <LayoutAdmin>

                <div className="container-fluid mb-5 mt-4">
                    <div className="row">
                        <div className="col-md-3 mb-4">
                            <Sidebar/>
                        </div>
                        <div className="col-md-9 mb-4">
                        
                            <div className="card border-0 rounded shadow">
                                <div className="card-body">

                                    <h6><i className="fas fa-image me-2"></i> GALERI FOTO</h6>
                                    <hr style={{ color: '#000000', backgroundColor: '#000000', height: .5, borderColor : '#000000'}}/>
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-striped">
                                            <thead>
                                            <tr>
                                                <th scope="col" width="6%" className="text-center">NO</th>
                                                <th scope="col" className="text-center">GAMBAR</th>
                                                <th scope="col">LOKAWISATA</th>
                                                <th scope="col" className="text-center" width="15%">AKSI</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {getData.map((photo, index) => (
                                                    <tr key={index}>
                                                        <td className="text-center">{++index + (currentPage-1) * perPage}</td>
                                                        <td className="text-center">
                                                            <img src={photo.image} alt="" width="100" className="rounded" />
                                                        </td>
                                                        <td>{photo.places.title}</td>
                                                        <td className="text-center">
                                                            <button onClick={() => deleteGallery(photo.id)} className="btn btn-sm btn-danger"><i className="fa fa-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <PaginationComponent 
                                        currentPage={currentPage} 
                                        perPage={perPage} 
                                        total={total} 
                                        onChange={(pageNumber) => fetchData(pageNumber)}
                                        position="end"
                                    />

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </LayoutAdmin>
        </React.Fragment>
    );
}

export default GalleryIndex;
