import React from "react";

//import Link
import {Link, useLocation} from 'react-router-dom';

function Sidebar() {

    //assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

    return (
        <React.Fragment>
            <div className="card border-0 rounded shadow">
                <div className="card-body">
                    <ul className="list-group">
                        <Link className={splitLocation[2] === "dashboard" ? "list-group-item text-uppercase active" : "list-group-item text-uppercase"} to="/admin/dashboard">
                            <i className="fa fa-laptop me-2"></i> Dashboard
                        </Link>
                        <Link className={splitLocation[2] === "places" ? "list-group-item text-uppercase active" : "list-group-item text-uppercase"} to="/admin/places">
                            <i className="fa fa-map-marked-alt me-2"></i> Lokawisata
                        </Link>
                        <Link className={splitLocation[2] === "gallery" ? "list-group-item text-uppercase active" : "list-group-item text-uppercase"} to="/admin/gallery">
                            <i className="fa fa-images me-2"></i> Galeri Foto
                        </Link>
                    </ul>
                </div>
            </div>
        </React.Fragment>
    )

}

export default Sidebar;