//import hook from react
import React, { useEffect, useState, useRef } from "react";

//import layout web
import LayoutWeb from "../../../layouts/Web";

//import BASE URL API
import Api from "../../../api";

//import mapbox gl
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

//api key mapbox
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX;

function WebMapsIndex() {
  //title page
  document.title = "Maps Destinations - Boalemo Tourism";

  //map container
  const mapContainer = useRef(null);

  //state coordinate
  const [coordinates, setCoordinates] = useState([]);

  //function "fetchDataPlaces"
  const fetchDataPlaces = async () => {
    //fetching Rest API
    await Api.get("/api/web/places/all").then((response) => {
      //set data to state
      setCoordinates(response.data.data);
    });
  };

  //hook
  useEffect(() => {
    //call function "fetchDataPlaces"
    fetchDataPlaces();
  }, []);

  //hook
  useEffect(() => {
    //init Map
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [122.1400982204172, -0.5289093904502283],
      zoom: 5,
    });

    // Create a default Marker and add it to the map.
    coordinates.forEach((location) => {
      // add popup
      const popup = new mapboxgl.Popup()
        .setHTML(
          `<h6><a href="/places/${location.slug}">${location.title}</a></h6><hr/><p><i className="fa fa-map-marker"></i> <i>${location.address}</i></p>`
        )
        .addTo(map);

      // add marker to map
      new mapboxgl.Marker()
        .setLngLat([location.longitude, location.latitude])
        .setPopup(popup)
        .addTo(map);
    });
  });

  return (
    <React.Fragment>
      <LayoutWeb>
        <div className="container mt-120 mb-3">
          <div className="row">
            <div className="col-md-12 mb-5">
              <div className="card border-0 rounded shadow-sm">
                <div className="card-body">
                  <h5>
                    <i className="fa fa-map-marked-alt"></i> PETA WISATA
                  </h5>
                  <hr />
                  <div
                    ref={mapContainer}
                    className="map-container"
                    style={{ height: "450px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <svg
          style={{ backgroundColor: "#d7eee9" }}
          className="d-none d-md-block"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 68"
        >
          <path
            d="m1622.3 1937.7c0 0-410.7 169.1-913.4 75.5-502.7-93.6-977.7 56.3-977.7 56.3v440h1891.1v-571.8"
            fill="#17233E"
            transform="translate(0-1977)"
          ></path>
        </svg>
      </LayoutWeb>
    </React.Fragment>
  );
}

export default WebMapsIndex;
