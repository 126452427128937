import React from "react";

//import ReactPlayer from "react-player";
import ReactPlayer from "react-player/youtube";

function CardYoutube(props) {
  return (
    <div className="col-md-6 col-lg-4 mb-4">
      <div className="card tour-card wow fadeIn">
        <ReactPlayer
          url={props.url}
          width="354px"
          height="245px"
          allowfullscreen
        />
      </div>
    </div>
  );
}

export default CardYoutube;
