//import react  
import React, {  } from "react";

//import layout admin
import LayoutAdmin from "../../../layouts/Admin";

import Sidebar from "../../../components/admin/Sidebar";

function Dashboard() {

	//title page
    document.title = "Beranda — SiParta Boalemo";

    return (
        <React.Fragment>
            <LayoutAdmin>

                <div className="container-fluid mb-5 mt-4">
                    <div className="row">
                        <div className="col-md-3 mb-4">
                            <Sidebar/>
                        </div>
                        <div className="col-md-9 mb-4">
                            <div className="card border-0 rounded shadow">
                                <div className="card-body">
                                    <h6><i className="fas fa-laptop me-2"></i> DASHBOARD</h6>
                                    <hr style={{ color: '#000000', backgroundColor: '#000000', height: .5, borderColor : '#000000'}}/>
                                    <p>Selamat Datang di Sistem Informasi Pariwisata Kabupaten Boalemo.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </LayoutAdmin>
        </React.Fragment>
    )

}

export default Dashboard;