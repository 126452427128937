//import React
import React, {useState, useEffect} from 'react';

import Siparda from "../assets/images/wonderful-indonesia.png";

//import CSS Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

//import custom CSS
import '../assets/css/admin.css';

//import component bootstrap
import { NavDropdown } from 'react-bootstrap';

//import BASE URL API
import Api from '../api';

//import js cookie
import Cookies from "js-cookie";

//hook link
import { useHistory, Link } from 'react-router-dom';

//import toats
import toast from "react-hot-toast";

const LayoutAdmin =({children}) =>{

    //state user
    const [user, setUser] = useState({});

    //history
    const history = useHistory();

    //token
    const token = Cookies.get("token");

    //fetchData
    const fetchData = async () => {

        //fetch on Rest API
        await Api.get('/api/admin/user', {
            headers: {
                
                //header Bearer + Token
                Authorization: `Bearer ${token}`,
            }
        })
        .then((response) => {

            //set state "user"
            setUser(response.data);
        })
    };

    //hook useEffect
    useEffect(() => {

        //call function "fetchData"
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //function logout
    const logoutHandler = async (e) => {
        e.preventDefault();

        await Api.post('/api/admin/logout', null, {
            headers: {
                
                //header Bearer + Token
                Authorization: `Bearer ${token}`,
            }
        })
        .then(() => {

            //remove token
            Cookies.remove('token');

            //show toast
            toast.success("Anda telah Keluar", {
                duration: 4000,
                position: "top-right",
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            });

            //redirect login page
            history.push('/admin/login');
        })
    }

    const styles = {

        main: {
            position: "relative",
            padding: "0.75rem 1.25rem",
            backgroundColor: "#029e9d",
            borderTop: "5px solid #07c2c2"
        },

    };

    return(
        <React.Fragment>
        
            <header className="section-header">
                <section style={styles.main} className="border-bottom">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-md-3 col-5">
                                <Link to="/" target="_blank"><img src={Siparda} style={{ width: "100px" }} alt="Boalemo Tourism"/></Link>
                            </div>
                            <div className="col-md-9 col-7">
                                <div className="d-flex justify-content-end">
                                    <NavDropdown
                                        title={user.name} style={{ color: "#fff" }} className="fw-bold text-uppercase" id="basic-nav-dropdown">
                                        {/* <NavDropdown.Item as={Link} to="/" target="_blank">
                                            <i className="fa fa-external-link-alt me-2"></i> Visit Web
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider /> */}
                                        <NavDropdown.Item as={Link} to={`/admin/users/edit/${user.id}`}>
                                            <i className="fa fa-user me-2"></i> <span className="text-capitalize">MY PROFILE</span>
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        {/* <NavDropdown.Item as={Link} to="/admin/places">
                                            <i className="fa fa-map-marked-alt me-2"></i> <span className="text-capitalize">Lokawisata</span>
                                        </NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/admin/gallery">
                                            <i className="fa fa-images me-2"></i> <span className="text-capitalize">Galeri Foto</span>
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider /> */}
                                        <NavDropdown.Item onClick={logoutHandler}>
                                            <i className="fa fa-sign-out-alt me-2"></i> <span className="text-capitalize">LOGOUT</span>
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </header>

            <div className="container-fluid">
                {children}
            </div>

        </React.Fragment>
    )
}

export default LayoutAdmin;