//import react
import React from "react";

import Logo from "../../assets/images/boalemo-tourism.png";

//import react router dom
import { Link } from "react-router-dom";

function WebFooter() {
  return (
    <React.Fragment>

      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-5">
              <div className="footer-widget">
                <img src={Logo} alt="Logo" />
                <p className="mt-3">
                  Boalemo Tourism adalah sebuah portal informasi Pariwisata
                  Boalemo. Situs ini dikelola oleh Dinas Pariwisata &amp;
                  Kebudayaan Kabupaten Boalemo.
                </p>
                <br />
                <ul className="team-social footer-social mt-2">
                  <li className="share-icon">
                    <Link to="/">
                      <i className="fab fa-facebook-f"></i>
                    </Link>
                  </li>
                  <li className="share-icon">
                    <Link to="/">
                      <i className="fab fa-twitter"></i>
                    </Link>
                  </li>
                  <li className="share-icon">
                    <Link to="/">
                      <i className="fab fa-instagram"></i>
                    </Link>
                  </li>
                  <li className="share-icon">
                    <Link to="/">
                      <i className="fab fa-youtube"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-4">
              <div className="footer-widget">
                <h5 className="mt-3">Tautan Link</h5>
                <ul>
                  <li>
                    <a
                      href="https://www.boalemokab.go.id"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Portal Kabupaten Boalemo
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://pariwisata.boalemokab.go.id/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Website Dinas Pariwisata Boalemo
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://kemenparekraf.go.id/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Kementerian Pariwisata dan Ekonomi Kreatif
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.indonesia.travel/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      The Official Website of Indonesia Tourism
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3">
              <div className="footer-widget">
                <h5 className="mt-3">Kontak Kami</h5>
                <p>Email <br /> admin@pariwisata.boalemokab.go.id</p>
                <p className="mt-2">WhatsApp <br /> +62 812-4426-8374</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="copyright">
                <p>
                  © 2022 Dinas Pariwisata &amp; Kebudayaan Kabupaten Boalemo.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default WebFooter;
