//import layout
import React, { useState, useEffect } from "react";

//import Banner from "../../../assets/img/pulocinta.png";

//import layout web
import LayoutWeb from "../../../layouts/Web";

//import Slider component
import Slider from "../../../components/web/Slider";

//import BASE URL API
import Api from "../../../api";

//import cart category component
import CardCategory from "../../../components/utilities/CardCategory";

//import react router dom
import { useHistory } from "react-router-dom";

function Home() {
  //title page
  document.title = "Boalemo Tourism";

  //history
  const history = useHistory();

  //state categories
  const [categories, setCategories] = useState([]);

  //state keyword
  const [keyword, setKeyword] = useState("");

  //function "fetchDataCategories"
  const fetchDataCategories = async () => {
    //fetching Rest API
    await Api.get("/api/web/categories/wisata").then((response) => {
      //set data to state
      setCategories(response.data.data);
    });
  };

  //hook
  useEffect(() => {
    //call function "fetchDataCategories"
    fetchDataCategories();
  }, []);

  //function "searchHandler"
  const searchHandler = () => {
    history.push(`/search?q=${keyword}`);
  };

  return (
    <React.Fragment>
      <LayoutWeb>
        <section className="carousel slide" id="banner" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="banner-caption">
                <div className="container">
                  <div className="row mt-20">
                    <div className="col-md-8 col-lg-7">
                      <div className="hero-text">
                        <h3 className="animated fadeInDown">Boalemo Tourism</h3>
                        <h1 className="animated fadeInUp">
                          Portal Informasi Wisata Boalemo
                        </h1>
                        <h5 className="animated fadeInDown">
                          Yuk, Rencanakan Perjalanan Anda ke Boalemo!
                        </h5>
                        <form className="maan-coupon-form">
                          <input
                            type="text"
                            className="form-control animated fadeInUp"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                            onKeyPress={(e) =>
                              e.key === "Enter" && searchHandler()
                            }
                            placeholder="Temukan Wisata impian disini..."
                          />
                          <button
                            type="submit"
                            className="btn btn-primary animated fadeInUp"
                          >
                            Jelajahi{" "}
                          </button>
                        </form>
                      </div>
                    </div>
                    <div className="col-lg-5 d-none d-md-none d-lg-block">
                      <div className="image-wrap mt-40">
                        <Slider />
                        {/* <img className="img-fluid" src={Banner} alt="Slider"/> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-spacing inverse-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title text-center">
                  <h5 className="mt-n6">Destinasi</h5>
                  <h2 className="mb-0">Objek Wisata</h2>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              {categories.map((category) => (
                <CardCategory
                  key={category.id}
                  id={category.id}
                  name={category.name}
                  slug={category.slug}
                  image={category.image}
                />
              ))}
            </div>
          </div>
        </section>

        <svg
          style={{ backgroundColor: "#fcfcfc" }}
          className="d-none d-md-block"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 68"
        >
          <path
            d="m1622.3 1937.7c0 0-410.7 169.1-913.4 75.5-502.7-93.6-977.7 56.3-977.7 56.3v440h1891.1v-571.8"
            fill="#17233E"
            transform="translate(0-1977)"
          ></path>
        </svg>

        {/* <section className="section-spacing blogs">
                  <div className="container">
                      <div className="row">
                          <div className="col-md-12">
                              <div className="section-title text-center">
                                  <h2 className="mb-0">Event Terbaru</h2>
                              </div>
                          </div>
                      </div>

                        <div className="row">

                            <div className="col-md-6 col-lg-4">
                                <a href="blog-single.html">
                                    <div className="card tour-card wow fadeIn">
                                        <img className="card-img-top" src={Berita} alt="Siparta Kabupaten Boalemo"/>
                                        <span className="tour-duration">
                                            <i className="fas fa-history"></i> 04 Dec </span>
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="flex-shrink-0">
                                                    <span className="align-self-start mr-3 media-icon">
                                                    <img src={Penulis} alt="Siparta Kabupaten Boalemo"/>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h5 className="m-0">Jony bristow</h5>
                                                    <p className="m-0">Admin</p>
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <span className="align-self-end"> 10:30 AM </span>
                                                </div>
                                            </div>
                                            <h6>A good traveler has no fixed plans and is not intent on arriving.</h6>
                                            <span className="read-more">Read More <i className="fas fa-arrow-right"></i>
                                            </span>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-md-6 col-lg-4">
                                <a href="blog-single.html">
                                    <div className="card tour-card wow fadeIn">
                                        <img className="card-img-top" src={Berita} alt="Siparta Kabupaten Boalemo"/>
                                        <span className="tour-duration">
                                            <i className="fas fa-history"></i> 04 Dec </span>
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="flex-shrink-0">
                                                    <span className="align-self-start mr-3 media-icon">
                                                    <img src={Penulis} alt="Siparta Kabupaten Boalemo"/>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h5 className="m-0">Jony bristow</h5>
                                                    <p className="m-0">Admin</p>
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <span className="align-self-end"> 10:30 AM </span>
                                                </div>
                                            </div>
                                            <h6>A good traveler has no fixed plans and is not intent on arriving.</h6>
                                            <span className="read-more">Read More <i className="fas fa-arrow-right"></i>
                                            </span>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-md-6 col-lg-4">
                                <a href="blog-single.html">
                                    <div className="card tour-card wow fadeIn">
                                        <img className="card-img-top" src={Berita} alt="Siparta Kabupaten Boalemo"/>
                                        <span className="tour-duration">
                                            <i className="fas fa-history"></i> 04 Dec </span>
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="flex-shrink-0">
                                                    <span className="align-self-start mr-3 media-icon">
                                                    <img src={Penulis} alt="Siparta Kabupaten Boalemo"/>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h5 className="m-0">Jony bristow</h5>
                                                    <p className="m-0">Admin</p>
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <span className="align-self-end"> 10:30 AM </span>
                                                </div>
                                            </div>
                                            <h6>A good traveler has no fixed plans and is not intent on arriving.</h6>
                                            <span className="read-more">Read More <i className="fas fa-arrow-right"></i>
                                            </span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            
                        </div>
                    
                  </div>
                </section> */}
      </LayoutWeb>
    </React.Fragment>
  );
}

export default Home;
