//import hook from react
import React, { useEffect, useState } from "react";

//import layout web
import LayoutWeb from "../../../layouts/Web";

//import BASE URL API
import Api from "../../../api";

//import card place component
import CardPlace from "../../../components/utilities/CardPlace";

//import pagination component
import PaginationComponent from "../../../components/utilities/Pagination";

function WebPlacesIndex() {
  //title page
  document.title = "Lokawisata - Boalemo Tourism";

  //state places
  const [places, setPlaces] = useState([]);

  //state currentPage
  const [currentPage, setCurrentPage] = useState(1);

  //state perPage
  const [perPage, setPerPage] = useState(0);

  //state total
  const [total, setTotal] = useState(0);

  //function "fetchDataPlaces"
  const fetchDataPlaces = async (pageNumber) => {
    //define variable "page"
    const page = pageNumber ? pageNumber : currentPage;

    //fetching Rest API
    await Api.get(`/api/web/places?page=${page}`).then((response) => {
      //set data to state "places"
      setPlaces(response.data.data.data);

      //set currentPage
      setCurrentPage(response.data.data.current_page);

      //set perPage
      setPerPage(response.data.data.per_page);

      //total
      setTotal(response.data.data.total);
    });
  };

  //hook
  useEffect(() => {
    //call function "fetchDataPlaces"
    fetchDataPlaces();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <LayoutWeb>
        <section
          className="inner-banner"
          style={{ backgroundImage: "url(/bg-destinations.png)" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="banner-content">
                  <h2>Boalemo Tourism</h2>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/">Beranda</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Lokawisata
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-spacing blogs">
          <div className="container">
            <div className="row">
              {places.length > 0 ? (
                places.map((place) => (
                  <CardPlace
                    key={place.id}
                    id={place.id}
                    slug={place.slug}
                    title={place.title}
                    images={place.images}
                    address={place.address}
                  />
                ))
              ) : (
                <div
                  className="alert alert-danger border-0 rounded shadow-sm"
                  role="alert"
                >
                  <strong>Opps...!</strong> Data Belum Tersedia!.
                </div>
              )}
            </div>
            <div className="row">
              <div className="pagination-container">
                <PaginationComponent
                  currentPage={currentPage}
                  perPage={perPage}
                  total={total}
                  onChange={(pageNumber) => fetchDataPlaces(pageNumber)}
                  position="center"
                />
              </div>
            </div>
          </div>
        </section>

        <svg
          style={{ backgroundColor: "#fcfcfc" }}
          className="d-none d-md-block"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 68"
        >
          <path
            d="m1622.3 1937.7c0 0-410.7 169.1-913.4 75.5-502.7-93.6-977.7 56.3-977.7 56.3v440h1891.1v-571.8"
            fill="#17233E"
            transform="translate(0-1977)"
          ></path>
        </svg>
      </LayoutWeb>
    </React.Fragment>
  );
}

export default WebPlacesIndex;
