//import hook react
import React, { useEffect, useState } from "react";

import Author from "../../../assets/images/author.jpg";

//import react router dom
import { useParams } from "react-router-dom";

//import layout web
import LayoutWeb from "../../../layouts/Web";

//import BASE URL API
import Api from "../../../api";

function WebEventsShow() {
  //state events
  const [events, setEvents] = useState({});

  //slug params
  const { slug } = useParams();

  //function "fetchDataEvent"
  const fetchDataEvent = async () => {
    //fetching Rest API
    await Api.get(`/api/web/events/${slug}`).then((response) => {
      //set data to state "events"
      setEvents(response.data.data);

      //set title from state "category"
      document.title = `${response.data.data.title} - Boalemo Tourism`;
    });
  };

  //hook
  useEffect(() => {
    //call function "fetchDataEvent"
    fetchDataEvent();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <LayoutWeb>
        <section
          className="inner-banner"
          style={{ backgroundImage: "url(/bg-destinations.png)" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="banner-content">
                  <h2>{events.title}</h2>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Lokasi: {events.location}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-spacing blogs">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="blog-single wow fadeIn">
                  <img
                    className="blog-image"
                    src={events.image}
                    alt={events.title}
                  />
                  <span className="tour-duration">
                    <i className="fas fa-history"></i> {events.date}
                  </span>

                  <div className="blog-single-author">
                    <div className="d-flex">
                      <div className="flex-shrink-0">
                        <span className="align-self-start mr-3 media-icon">
                          <img src={Author} alt="" />
                        </span>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h5 className="m-0">Administrator</h5>
                        <p className="m-0">Pengirim</p>
                      </div>
                      <div className="flex-shrink-0">
                        <span className="align-self-end"> {events.date}</span>
                      </div>
                    </div>
                  </div>

                  <h5 className="blog-single-title">{events.title}.</h5>
                  <p className="blog-single-content">
                    <div dangerouslySetInnerHTML={{ __html: events.content }} />
                  </p>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="blog-right-content"></div>
              </div>
            </div>
          </div>
        </section>

        <svg
          style={{ backgroundColor: "#fcfcfc" }}
          className="d-none d-md-block"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 68"
        >
          <path
            d="m1622.3 1937.7c0 0-410.7 169.1-913.4 75.5-502.7-93.6-977.7 56.3-977.7 56.3v440h1891.1v-571.8"
            fill="#17233E"
            transform="translate(0-1977)"
          ></path>
        </svg>
      </LayoutWeb>
    </React.Fragment>
  );
}

export default WebEventsShow;
