//import layout
import React, { useEffect, useState, useRef } from "react";

//import react router dom
import { Link, useParams, useLocation } from "react-router-dom";

//import layout web
import LayoutWeb from "../../../layouts/Web";

//import mapbox gl
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

//import mapbox gl direction
import Directions from "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions";

//api key mapbox
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX;

function WebPlaceDirection() {
  //title page
  document.title = "Map Direction - Boalemo Tourism";

  //map container
  const mapContainer = useRef(null);

  //state for user location coordinate
  const [longitude, setLongitude] = useState(122.8447577202383);
  const [latitude, setLatitude] = useState(0.6410289999994632);

  //slug params
  const { slug } = useParams();

  //get query params
  const query = new URLSearchParams(useLocation().search);

  //hook
  useEffect(() => {
    //init Map
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [query.get("longitude"), query.get("latitude")],
      zoom: 6,
    });

    //init geolocate
    const geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      // When active the map will receive updates to the device's location as it changes.
      trackUserLocation: true,
      // Draw an arrow next to the location dot to indicate which direction the device is heading.
      showUserHeading: true,
    });

    // Add geolocate control to the map.
    map.addControl(geolocate);

    //init directions
    const directions = new Directions({
      accessToken: mapboxgl.accessToken,
      unit: "metric",
      profile: "mapbox/driving",
      // UI controls
      controls: {
        inputs: false,
        instructions: true,
      },
    });

    // Add directions to the map.
    map.on("load", function () {
      geolocate.trigger(); //<- Automatically activates geolocation

      geolocate.on("geolocate", function (position) {
        //set value state `longitude`
        setLongitude(position.coords.longitude);

        //set value state `latitude`
        setLatitude(position.coords.latitude);
      });

      //set origin and destination for directions route
      directions.setOrigin([longitude, latitude]);
      directions.setDestination([
        query.get("longitude"),
        query.get("latitude"),
      ]);

      //add directions to map
      map.addControl(directions);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <LayoutWeb>
        <div className="container mt-120 mb-3">
          <div className="row">
            <div className="col-md-12 mb-5">
              <div className="card border-0 rounded shadow-sm">
                <div className="card-body">
                  <Link
                    to={`/places/${slug}`}
                    className="float-end btn btn-success btn-sm mb-2"
                  >
                    <i className="fa fa-long-arrow-alt-left"></i> KEMBALI
                  </Link>
                  <h5>
                    <i className="fa fa-location-arrow"></i> ARAH DARI LOKASI
                    ANDA
                  </h5>
                  <hr />
                  <div
                    ref={mapContainer}
                    className="map-container"
                    style={{ height: "400px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <svg
          style={{ backgroundColor: "#d7eee9" }}
          className="d-none d-md-block"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 68"
        >
          <path
            d="m1622.3 1937.7c0 0-410.7 169.1-913.4 75.5-502.7-93.6-977.7 56.3-977.7 56.3v440h1891.1v-571.8"
            fill="#17233E"
            transform="translate(0-1977)"
          ></path>
        </svg>
      </LayoutWeb>
    </React.Fragment>
  );
}

export default WebPlaceDirection;
