//import react router dom
import { Link } from "react-router-dom";

function CardPlace(props) {
  return (
    <div className="col-md-6 col-lg-4 mb-4">
      <Link to={`/places/${props.slug}`}>
        <div className="card tour-card wow fadeIn">
          {props.images.slice(0, 1).map((placeImage) => (
            <img
              src={placeImage.image}
              className="card-img-top"
              alt="siparda.boalemokab.go.id"
              style={{ height: "245px", objectFit: "cover" }}
              key={placeImage.id}
            />
          ))}
          <div className="card-body" style={{ height: "175px" }}>
            <h6>{props.title}.</h6>
            <p className="card-text">
              <i className="fa fa-map-marker"></i> <i>{props.address}</i>
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default CardPlace;
