//import react router dom
import { Link } from "react-router-dom";

function CardEvent(props) {
  return (
    <div className="col-md-6 col-lg-4 mb-4">
      <Link to={`/events/${props.slug}`}>
        <div className="card tour-card wow fadeIn">
          <img
            className="card-img-top"
            src={props.images}
            style={{ height: "245px", objectFit: "cover" }}
            alt={props.title}
          />
          <span className="tour-duration">
            <i className="fas fa-history"></i> {props.tanggal}
          </span>
          <div className="card-body" style={{ height: "190px" }}>
            <h6>{props.title}.</h6>
            <p className="card-text">
              <i className="fa fa-map-marker"></i> <i>{props.location}</i>
            </p>
            <span className="read-more">
              Selengkapnya <i className="fas fa-arrow-right"></i>
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default CardEvent;
