//import react router dom
import { Switch, Route } from "react-router-dom";

//import component private routes
import PrivateRoute from "./PrivateRoutes";

//=======================================================================
//ADMIN
//=======================================================================

import Login from "../pages/admin/Login";

import Dashboard from "../pages/admin/dashboard/Index";

import PlacesIndex from "../pages/admin/places/Index";

import PlaceCreate from "../pages/admin/places/Create";

import PlaceEdit from "../pages/admin/places/Edit";

import GalleryIndex from "../pages/admin/gallery/Index";

import UserEdit from "../pages/admin/users/Edit";

//=======================================================================
//WEB
//=======================================================================

import Home from "../pages/web/home/Index";

import WebCategoryShow from "../pages/web/categories/Show";

import WebDestinationsShow from "../pages/web/destinations/Show";

import WebPlaceIndex from "../pages/web/places/Index";

import WebPlaceShow from "../pages/web/places/Show";

import WebPlaceDirection from "../pages/web/places/Direction";

import WebVideoIndex from "../pages/web/videos/Index";

import WebEventsIndex from "../pages/web/events/Index";

import WebEventsShow from "../pages/web/events/Show";

import MapsIndex from "../pages/web/maps/Index";

import WebSearch from "../pages/web/search/Index";

function Routes() {
  return (
    <Switch>
      <Route exact path="/admin/login">
        <Login />
      </Route>

      <PrivateRoute exact path="/admin/dashboard">
        <Dashboard />
      </PrivateRoute>

      <PrivateRoute exact path="/admin/places">
        <PlacesIndex />
      </PrivateRoute>

      <PrivateRoute exact path="/admin/places/create">
        <PlaceCreate />
      </PrivateRoute>

      <PrivateRoute exact path="/admin/places/edit/:id">
        <PlaceEdit />
      </PrivateRoute>

      <PrivateRoute exact path="/admin/gallery">
        <GalleryIndex />
      </PrivateRoute>

      {/* private route "/admin/users/edit/:id" */}
      <PrivateRoute exact path="/admin/users/edit/:id">
        <UserEdit />
      </PrivateRoute>

      {/* route web "/" */}
      <Route exact path="/">
        <Home />
      </Route>

      <Route exact path="/category/:slug">
        <WebCategoryShow />
      </Route>

      <Route exact path="/destinations/:slug">
        <WebDestinationsShow />
      </Route>

      <Route exact path="/places">
        <WebPlaceIndex />
      </Route>

      <Route exact path="/places/:slug">
        <WebPlaceShow />
      </Route>

      <Route exact path="/places/:slug/direction">
        <WebPlaceDirection />
      </Route>

      <Route exact path="/videos">
        <WebVideoIndex />
      </Route>

      <Route exact path="/events">
        <WebEventsIndex />
      </Route>

      <Route exact path="/events/:slug">
        <WebEventsShow />
      </Route>

      <Route exact path="/maps">
        <MapsIndex />
      </Route>

      <Route exact path="/search">
        <WebSearch />
      </Route>
    </Switch>
  );
}

export default Routes;
